button {
  font-family: Quicksand;
  font-weight: 700;
}

.cookie-consent-details {
  background: var(--purple) !important;
  height: 35px;
  width: 150px;
  font-size: 0.9em !important;
}

.cookie-consent-details:hover {
  background: var(--dark-purple) !important;
}

.cookie-consent-accept {
  background: #04bd51 !important;
  height: 35px;
  width: 150px;
  color: white !important;
  font-size: 0.9em !important;
}

.cookie-consent-accept:hover {
  background: #04863a !important;
}

.cookie-consent-details:focus,
.cookie-consent-accept:focus {
  outline: none !important;
  border: none !important;
}

.cookie-consent-container {
  display: flex;
  align-items: center !important;
}
