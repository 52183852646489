.privacy-policy header {
  background: var(--purple);
  margin: 0;
  text-align: center;
  padding: 0.5em;
}

.privacy-policy header a {
  color: white;
}

.privacy-policy header h2 {
  color: white;
  font-size: 2.5em;
  letter-spacing: 1px;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.privacy-policy .page-title {
  background: var(--purple);
  text-align: center;
}

.privacy-policy .page-title h1 {
  font-weight: 500;
  color: white;
  font-size: 1.4em;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.privacy-policy .content-wrapper {
  display: flex;
  justify-content: center;
}

.privacy-policy .content {
  width: 70%;
}

.privacy-policy .section-list {
  font: 20px Quicksand-Bold, san-serif;
}

.privacy-policy .section-list li {
  color: var(--pink);
}

.privacy-policy .section-list li h3 {
  font-size: 20px;
}

.privacy-policy .article {
  font-size: 15px;
  font-family: Quicksand-Regular;
  color: var(--black);
  line-height: 1.7;
}

.privacy-policy .article-list li {
  color: var(--purple);
  font-size: 15px;
  font-family: Quicksand-Bold;
}

.before-footer-container {
  margin-top: 3em;
  display: flex;
  justify-content: center;
}

.before-footer {
  width: 30%;
  border-top: 1px solid var(--black);
}

@media (max-width: 680px) {
  .privacy-policy .responsive-ol {
    padding-left: 0;
  }
}
